import { createStore } from 'vuex'
import axios from 'axios';

export default createStore({
  state: {
    server_info: true,
    user_info: {},
    auth: false,
  },
  getters: {
  },
  mutations: {
    setAuth(state, value) {
      state.auth = value;
    },
  },
  actions: {
    postDataToServer(context, params) {
      return new Promise((resolve, reject) => {
        const apiUrl = 'https://wabix.kz/whatsapp/api.php';
        axios.post(apiUrl, params, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          if(response.data['type']){
            resolve(response.data);
          }else{
            reject({type: false, msg: response.data['msg']});
          }
        })
        .catch(error => {
          reject({type: false, msg: 'Не удалось выполнить запрос, проверьте соединение... '+error});
        });
      });
    },
  },
  modules: {
  }
})
