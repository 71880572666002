<template>
  <div v-if="load" class="row mb-4">
    <div v-if="!loadErr" class="col-lg-12 col-md-12 mb-md-0 mb-4">
      <div class="card">
        <div class="card-header pb-0">
          <div class="row">
            <div class="col-lg-6 col-7">
              <h6>Whatsapp Каналы</h6>
              <p class="text-sm mb-0">
                <i class="fa fa-check text-info" aria-hidden="true"></i>
                <span class="font-weight-bold ms-1">Каналы (Боты) для работы с Whatsapp</span>
              </p>
            </div>
            <div class="col-lg-6 col-5 my-auto text-end">
              <div class="dropdown float-lg-end pe-4">
                <a class="cursor-pointer" id="dropdownTable" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="fa fa-ellipsis-v text-secondary"></i>
                </a>
                <ul class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5" aria-labelledby="dropdownTable">
                  <li><button class="dropdown-item border-radius-md" @click="addChanel()">Создать канал</button></li>
                  <li><button class="dropdown-item border-radius-md" @click="loadData()">Обновить данные</button></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body px-0 pb-2">
          <div class="table-responsive">
            <table class="table align-items-center table-striped table-hover mb-0">
              <thead>
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Название Канала</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Статус</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Принято сбщ.</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Отправлено сбщ.</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Всего диалогов.</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="chanel in chanels" :key="chanel.id">
                  <td>
                    <div class="d-flex px-2 py-1">
                      <div>
                        <img :src="chanel['avatar'].length > 0 ? chanel['avatar']: this.$store.state.server_info.defoult_avatar" class="avatar avatar-md me-3" alt="xd"/>
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">Канал: <br><strong>{{ chanel['name'] }}</strong></h6>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle text-center text-sm">
                    <span class="badge" :style="{ backgroundColor: chanel['status_info']['color'] }">{{ chanel['status_info']['name'] }}</span>
                  </td>
                  <td class="align-middle text-center text-sm">
                    <span class="text-xs font-weight-bold">{{ chanel['count_messages_in'] }}</span>
                  </td>
                  <td class="align-middle text-center text-sm">
                    <span class="text-xs font-weight-bold">{{ chanel['count_messages_out'] }}</span>
                  </td>
                  <td class="align-middle text-center text-sm">
                    <span class="text-xs font-weight-bold">{{ chanel['count_dialogs'] }}</span>
                  </td>
                  <td class="align-middle text-center">
                    <div class="btn-group" role="group" aria-label="Basic example">
                      <a @click="openChanel(chanel['id'])" class="btn btn-primary btn sm">Открыть</a>
                      <a @click="deleteChanel(chanel['id'])" class="btn btn-outline-danger btn sm">Удалить</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <ErrPageLoad :text="errLoadText" :myFunction="loadData" v-else></ErrPageLoad>
  </div>
  <PreloaderPage v-else></PreloaderPage>
</template>

<script>

export default {
  name: 'HomeView',
  created() {
    this.loadData();
  },
  data() {
    return {
      load: false,
      loadErr: false,
      errLoadText: '',
      chanels: [],
      test: {}
    };
  },
  props: {},
  methods: {
    openChanel(chanelId){
      this.$router.push({ name: 'only_chanel', params: { channelId: chanelId } });
    },
    async deleteChanel(chanelId){
      this.load = false;
      this.loadErr = false;
      this.errLoadText = '';
      await this.$store.dispatch('postDataToServer', {
        methods: 'chanels/delete_chanel',
        myId: this.$store.state.user_info.id,
        chanel_id: chanelId,
      })
      .then(result => {
        this.chanels = result['chanels'];
        this.load = true;
        this.loadErr = false;
        this.errLoadText = '';
        this.test = result.test;
      })
      .catch(error => {
        setTimeout(() => {
          this.load = true;
          this.loadErr = true;
          this.errLoadText = error.msg;
        }, 1000);
      });
    },
    async addChanel(){
      this.load = false;
      this.loadErr = false;
      this.errLoadText = '';
      await this.$store.dispatch('postDataToServer', {
        methods: 'chanels/add_chanel',
        myId: this.$store.state.user_info.id,
      })
      .then(result => {
        this.chanels = result['chanels'];
        this.load = true;
        this.loadErr = false;
        this.errLoadText = '';
      })
      .catch(error => {
        setTimeout(() => {
          this.load = true;
          this.loadErr = true;
          this.errLoadText = error.msg;
        }, 1000);
      });
    },
    async loadData(){
      this.load = false;
      this.loadErr = false;
      this.errLoadText = '';
      await this.$store.dispatch('postDataToServer', {
        methods: 'chanels/load_list_chanels',
        myId: this.$store.state.user_info.id,
      })
      .then(result => {
        this.chanels = result['chanels'];
        this.load = true;
        this.loadErr = false;
        this.errLoadText = '';
      })
      .catch(error => {
        setTimeout(() => {
          this.load = true;
          this.loadErr = true;
          this.errLoadText = error.msg;
        }, 1000);
      });
    }
  },
  components: {}
}
</script>
