<template>
  <div class="preloaderPage d-flex align-items-center justify-content-center ">
      <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
      </div>
  </div>
</template>

<script>
export default {
  name: "PreloaderPage",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="css" scoped>
  .preloaderPage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }
  .spinner-border{
    margin: auto;
  }
</style>