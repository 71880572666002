<template>
  <div v-if="load">
    <div v-if="!loadErr">
      <div class="row mb-5 d-flex align-items-center">
        <div class="col-auto">
          <div class="avatar-background"
            :style="{ backgroundImage: `url(${chanelInfo['avatar'].length > 0 ? chanelInfo['avatar'] : this.$store.state.server_info.defoult_avatar})` }">
          </div>
        </div>
        <div class="col-auto">
          <h4>Канал: №{{ chanelInfo.id }}<br><small>Управление каналом</small></h4>
          <div v-if="chanelInfo['userName'].length > 0"><span>{{ chanelInfo['userName'] }}</span> <br></div>
          <div v-if="chanelInfo['real_phone'].length > 0"><span>{{ chanelInfo['real_phone'] }}</span> <br></div>
          <span class="badge" :style="{ backgroundColor: chanelInfo.status_info.color }">{{ chanelInfo.status_info.name }}</span>
        </div>
        <div class="col">
          <div class="row">
            <div class="col-md-3">
              <div class="stat-cards-1">
                <div class="row d-flex align-items-center justify-content-center">
                  <div class="col-auto text-center">
                    <i class="ion-ios-paperplane-outline iconStats"></i>
                  </div>
                  <div class="col-auto text-left">
                    <strong>Получено</strong><br>
                    Сообщений
                  </div>
                  <div class="col-auto text-center counterStat">
                    {{ chanelInfo.count_messages_in }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="stat-cards-2">
                <div class="row d-flex align-items-center justify-content-center">
                  <div class="col-auto text-center">
                    <i class="ion-chatbubble-working iconStats"></i>
                  </div>
                  <div class="col-auto text-left">
                    <strong>Отправлено</strong><br>
                    Сообщения
                  </div>
                  <div class="col-auto text-center counterStat">
                    {{ chanelInfo.count_messages_out }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="stat-cards-3">
                <div class="row d-flex align-items-center justify-content-center">
                  <div class="col-auto text-center">
                    <i class="ion-chatbubble-working iconStats"></i>
                  </div>
                  <div class="col-auto text-left">
                    <strong>Всего</strong><br>
                    Диалогов
                  </div>
                  <div class="col-auto text-center counterStat">
                    {{ chanelInfo.count_dialogs }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="stat-cards-4">
                <div class="row d-flex align-items-center justify-content-center">
                  <div class="col-auto text-center">
                    <i class="ion-pie-graph iconStats"></i>
                  </div>
                  <div class="col-auto text-left">
                    <strong>Достигнуто</strong><br>
                    целей
                  </div>
                  <div class="col-auto text-center counterStat">
                    {{ chanelInfo.count_perfect }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-group" role="group" aria-label="Basic example">
        <a v-if="chanelInfo.status_info.is_active != 1" @click="selectTabs(1)"
          :class="tabs == 1 ? 'btn btn-primary btn sm' : 'btn btn-outline-primary btn sm'">Подключение</a>
        <a @click="selectTabs(2)"
          :class="tabs == 2 ? 'btn btn-primary btn sm' : 'btn btn-outline-primary btn sm'">Настройки</a>
        <a @click="selectTabs(3)"
          :class="tabs == 3 ? 'btn btn-primary btn sm' : 'btn btn-outline-primary btn sm'">Сценарий</a>
        <a v-if="chanelInfo.status_info.is_active == 1" @click="selectTabs(4)"
          :class="tabs == 4 ? 'btn btn-primary btn sm' : 'btn btn-outline-primary btn sm'">Диалоги</a>
        <button :disabled="closedChanel" @click="closeChanel()" v-if="chanelInfo.status_info.is_active == 1" class="btn btn-danger btn sm"> 
          <span v-if="!closedChanel">Отключить канал</span>
          <span v-if="closedChanel" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
        </button>
      </div>

      <div v-if="tabs == 1" class="w-100 tabsWindow d-flex align-items-center justify-content-center">
        <div v-if="chanelInfo.status_info.is_qr == 0 && chanelInfo.qr_registered.length == 0" class="text-center">
          <i style="font-size: 65px;" class="ion-qr-scanner"></i>
          <h2>Whatsapp не подключен!</h2>
          <p>Сгенерируйте QR и подключите к Whatapp на устройстве!</p>
          <button @click="startChanel()" :disabled="startedChanels" class="btn btn-primary">
            <span v-if="!startedChanels">Подключить</span>
            <span v-if="startedChanels" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
          </button>
        </div>
        <div v-else class="text-center">
          <QRCodeVue3
            :width="400"
            :height="400"
            :value="chanelInfo.qr_registered"
            image='assets/img/wabix-icon.png'
            :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
            :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 10 }"
            :dotsOptions="{
              type: 'dots',
              color: '#008000', 
              gradient: {
                type: 'linear',
                rotation: 0,
                colorStops: [
                  { offset: 0, color: '#008000' },
                  { offset: 1, color: '#008000' },
                ],
              },
            }"
            :backgroundOptions="{ color: '#ffffff' }"
            :cornersSquareOptions="{ type: 'dot', color: '#000000' }"
            :cornersDotOptions="{ type: undefined, color: '#000000' }"
          />
          <p class="mt-2"><strong>Пожалуйста отсканируйте QR код для запуска канала...</strong></p>
          <button class="btn btn-primary mt-3">Перезагрузить</button>
        </div>
      </div>


      <div v-if="tabs == 2" class="w-100 tabsWindow">
        <div class="text-center w-100">
          <form class="w-100">
            <div class="mb-3 input-group input-group-outline w-100">
              <input v-model="chanelInfo['name']" placeholder="Название канала" name="chanelName" type="text" class="form-control w-100" id="titleChanel">
            </div>
            <div class="mb-3 input-group input-group-outline w-100">
              <input v-model="chanelInfo['max_messages_day']" placeholder="В день максимально новых сообщений" name="maxMessages" type="number" class="form-control w-100" id="maxMessages">
            </div>
            <div class="mb-3 input-group input-group-outline w-100">
              <input v-model="chanelInfo['proxy']" placeholder="Прокси сервер" name="maxMessages" type="text" class="form-control w-100" id="maxMessages">
            </div>
            <div class="mb-3 input-group input-group-outline w-100">
              <input v-model="chanelInfo['proxy_auth']" placeholder="Прокси авторизация" name="maxMessages" type="text" class="form-control w-100" id="maxMessages">
            </div>
            <div class="mb-3 input-group input-group-outline w-100">
              <textarea v-model="chanelInfo['bot_description_data']" name="instruktionsBot" placeholder="Инструкции для бота" rows="7" class="form-control w-100" id="instructions"></textarea>
            </div>
            <button @click="updateChanelInfoFunc()" :disabled="updateChanelInfo" class="btn btn-primary w-100">
              <span v-if="!updateChanelInfo">Сохранить</span>
              <span v-if="updateChanelInfo" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            </button>
          </form>
        </div>
      </div>


      <div v-if="tabs == 3" class="w-100 tabsWindow">
        <div class="text-center w-100">
          <form class="w-100">
            <div class="input-group mb-3 input-group-outline w-100 selectGroup">
              <select v-model="this.selectScripts" class="form-select form-control h-100">
                <option value="newScript" selected>Создать новый скрипт...</option>
                <option value="defoultScript">Код по умолчанию...</option>
                <option v-for="scrpt in list_scripts" :key="scrpt" :value="scrpt">{{ scrpt }}</option>
              </select>
              <button @click="selectedScripts()" :disabled="loadScripts" class="btn btn-outline-secondary" type="button" id="button-addon1">
                <span v-if="!loadScripts">Выбрать</span>
                <span v-if="loadScripts" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
              </button>
            </div>
          </form>

          <div v-if="this.selectScripts != 'newScript' && this.selectScripts != 'defoultScript'" style="text-align: left;">
            <Codemirror
              v-model:value="code"
              :options="cmOptions"
              border
              placeholder="test placeholder"
              :height="500"
              @change="change"
            />
          </div>
        </div>
      </div>


      <div v-if="tabs == 4" class="w-100 tabsWindow">
        <div v-if="loadDialogs" class="text-center w-100 chatBackground">
          <div @click="openChats(chat.id.user)" v-for="chat in dialogs" :key="chat.id.user" class="row listChat p-3">
            <div class="col-auto p-1">
              <div class="avatar-background-chats" :style="{ backgroundImage: 'url('+this.$store.state.server_info.defoult_avatar+')'  }"></div>
            </div>
            <div class="col-11 text-left">
              <strong class="titleChats">{{ chat.name }}</strong><br>
              <span>{{ chat.lastMessage?.type == 'chat' ? chat.lastMessage?.body : 'Медиа сообщение...' }}</span>
            </div>
          </div>
        </div>
        <PreloaderPage v-else></PreloaderPage>
      </div>
    </div>
    <ErrPageLoad :text="loadErrText" :myFunction="startPage" v-else></ErrPageLoad>
  </div>
  <PreloaderPage v-else></PreloaderPage>
</template>

<script>
import "codemirror/addon/display/placeholder.js";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/addon/display/placeholder.js";
import "codemirror/theme/dracula.css";
import QRCodeVue3 from "qrcode-vue3";

export default {
  name: "OnlyChanel",
  created() {
   
    this.startPage();
  },
  components: {
    QRCodeVue3
  },
  mounted() {
    this.timerUpdate = setInterval(() => {
      this.updateDataInterval();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timerUpdate);
  },
  data() {
    return {
      load: false,
      loadErr: false,
      loadErrText: '',
      selectScripts: 'newScript',
      loadScripts: false,
      startedChanels: false,
      loadDialogs: false,
      tabs: 1,
      code: '',
      updateChanelInfo: false,
      list_scripts: [],
      dialogs: [],
      closedChanel: false,
      chanelInfo: {
        avatar: '',
      }
    };
  },
  props: {},
  watch: {
    'tabs': {
      handler: async function(newValue) {
        if(newValue == 4 && this.chanelInfo.status_info.is_active == 1){
          this.loadDialogs = false;
          await this.$store.dispatch('postDataToServer', {
            methods: 'chanels/get_dialogs',
            myId: this.$store.state.user_info.id,
            chanel_id: this.$route.params.channelId,
          })
          .then(result => {
            this.dialogs = result.dialogs;
            console.log(result);
          }) 
          .catch(error => {
            this.$notify({
              type: 'error',
              title: 'Произошла ошибка!',
              text: error.msg,
            });
          });
          this.loadDialogs = true;
        }
      },
      deep: true
    }
  },
  setup() {
    return {
      cmOptions: {
        mode: "text/javascript",
        theme: "dracula",
      },
    };
  },
  methods: {
    async closeChanel(){
      this.closedChanel = true;
      await this.$store.dispatch('postDataToServer', {
        methods: 'chanels/close_chanel',
        myId: this.$store.state.user_info.id,
        chanel_id: this.$route.params.channelId,
      })
      .then(result => {
        console.log(result);
        this.startPage();
      }) 
      .catch(error => {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка!',
          text: error.msg,
        });
      });
      this.closedChanel = false;
    },
    async openChats(chatID){
      this.$router.push({ name: 'only_chat', params: { channelId: this.$route.params.channelId, chatId:  chatID} }); 
    },
    async startChanel(){
      this.startedChanels = true;
      await this.$store.dispatch('postDataToServer', {
        methods: 'chanels/started_chanel',
        myId: this.$store.state.user_info.id,
        chanel_id: this.$route.params.channelId,
        chanel_info: this.chanelInfo,
      })
      .then(result => {
        console.log(result);
        this.startPage();
      }) 
      .catch(error => {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка!',
          text: error.msg,
        });
      });
      this.startedChanels = false;
    },
    async updateChanelInfoFunc(){
      this.updateChanelInfo = true;
      await this.$store.dispatch('postDataToServer', {
        methods: 'chanels/update_chanel_info',
        myId: this.$store.state.user_info.id,
        chanel_id: this.$route.params.channelId,
        chanel_info: this.chanelInfo,
      })
      .then(result => {
        this.chanelInfo = result.chanel_info;
        this.list_scripts = result.list_scripts;
        this.selectScripts = this.chanelInfo.scenaries_file.length > 0 ? this.chanelInfo.scenaries_file : 'newScript';
        if(this.chanelInfo.status_info.is_active == 1){
          this.tabs = 2;
        }else if(this.chanelInfo.status_info.is_off == 1){
          this.tabs = 1;
        }else if(this.chanelInfo.status_info.is_qr == 1){
          this.tabs = 1;
        }
      }) 
      .catch(error => {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка!',
          text: error.msg,
        });
      });
      this.updateChanelInfo = false;
    },
    async change(){
      await this.$store.dispatch('postDataToServer', {
        methods: 'chanels/update_code',
        myId: this.$store.state.user_info.id,
        chanel_id: this.$route.params.channelId,
        scripts: this.selectScripts,
        update_code: this.code,
      })
      .then(result => {
        this.chanelInfo = result.chanel_info;
        this.list_scripts = result.list_scripts;
        this.selectScripts = this.chanelInfo.scenaries_file.length > 0 ? this.chanelInfo.scenaries_file : 'newScript';
        if(this.chanelInfo.status_info.is_active == 1){
          this.tabs = 2;
        }else if(this.chanelInfo.status_info.is_off == 1){
          this.tabs = 1;
        }else if(this.chanelInfo.status_info.is_qr == 1){
          this.tabs = 1;
        }
      }) 
      .catch(error => {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка!',
          text: error.msg,
        });
      });
    },
    async selectedScripts(){
      this.loadScripts = true;
      await this.$store.dispatch('postDataToServer', {
        methods: 'chanels/selected_scripts',
        myId: this.$store.state.user_info.id,
        chanel_id: this.$route.params.channelId,
        scripts: this.selectScripts,
      })
      .then(result => {
        this.chanelInfo = result.chanel_info;
        this.list_scripts = result.list_scripts;
        this.code = this.chanelInfo.scenaries_file_code;
        this.selectScripts = this.chanelInfo.scenaries_file.length > 0 ? this.chanelInfo.scenaries_file : 'newScript';
        if(this.chanelInfo.status_info.is_active == 1){
          this.tabs = 2;
        }else if(this.chanelInfo.status_info.is_off == 1){
          this.tabs = 1;
        }else if(this.chanelInfo.status_info.is_qr == 1){
          this.tabs = 1;
        }
      })
      .catch(error => {
        console.log(error);
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка!',
          text: 'Не удалось обновить данные на сервере...',
        });
      });
      this.loadScripts = false;
    },
    async updateDataInterval(){
      await this.$store.dispatch('postDataToServer', {
        methods: 'chanels/chanel_info',
        myId: this.$store.state.user_info.id,
        chanel_id: this.$route.params.channelId,
      })
      .then(result => {
        result.chanel_info['name'] = this.chanelInfo['name'];
        result.chanel_info['max_messages_day'] = this.chanelInfo['max_messages_day'];
        result.chanel_info['bot_description_data'] = this.chanelInfo['bot_description_data'];
        result.chanel_info['name'] = this.chanelInfo['name'];
        result.chanel_info['proxy'] = this.chanelInfo['proxy'];
        result.chanel_info['proxy_auth'] = this.chanelInfo['proxy_auth'];
        if(this.chanelInfo.status_info.code != result.chanel_info.status_info.code){
          if(result.chanel_info.status_info.is_active == 1){
            this.tabs = 2;
          }else if(result.chanel_info.status_info.is_off == 1){
            this.tabs = 1;
          }else if(result.chanel_info.status_info.is_qr == 1){
            this.tabs = 1;
          }
        }
        this.chanelInfo = result.chanel_info;
        this.list_scripts = result.list_scripts;
      });
    },
    async startPage(){
      this.load = false;
      this.loadErr = false;
      this.errLoadText = '';
      await this.$store.dispatch('postDataToServer', {
        methods: 'chanels/chanel_info',
        myId: this.$store.state.user_info.id,
        chanel_id: this.$route.params.channelId,
      })
      .then(result => {
        this.chanelInfo = result.chanel_info;
        this.list_scripts = result.list_scripts;
        this.selectScripts = this.chanelInfo.scenaries_file.length > 0 ? this.chanelInfo.scenaries_file : 'newScript';
        this.load = true;
        this.loadErr = false;
        this.loadErrText = '';
        this.code = this.chanelInfo.scenaries_file_code;
        if(this.chanelInfo.status_info.is_active == 1){
          this.tabs = 2;
        }else if(this.chanelInfo.status_info.is_off == 1){
          this.tabs = 1;
        }else if(this.chanelInfo.status_info.is_qr == 1){
          this.tabs = 1;
        }
      })
      .catch(error => {
        setTimeout(() => {
          this.load = true;
          this.loadErr = true;
          this.loadErrText = error.msg;
        }, 1000);
      });
    },
    selectTabs(tabs) {
      this.tabs = tabs;
    }
  },
};
</script>

<style lang="css" scoped>
  .tabsWindow {
    min-height: 60vh;
  }

  .titleChats{
    color: #31f300;
  }

  .listChat{
    border-bottom: solid 2px #0a3100;
    padding-bottom: 10px;
    width: 99%;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 5px;
    cursor: pointer;
  }

  .listChat:last-child{
    border-bottom: none;
    padding-bottom: 5px;
    margin-bottom: 0px;
  }

  .chatBackground{
    background-color: rgb(0, 31, 18);
    padding: 10px;
    color: white;
    border-radius: 5px;
  }

  .avatar-background-chats{
    width: 60px;
    height: 60px;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
  }

  .avatar-background {
    width: 150px;
    height: 150px;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
  }

  .selectGroup{
    height: 50px;
  }

  .selectGroup button{
    height: 100%;
  }
  .selectGroup select{
    height: 100%;
  }
</style>