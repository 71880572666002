<template>
  <footer class="footer py-4  ">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="col-lg-6 mb-lg-0 mb-4">
          <div class="copyright text-center text-sm text-muted text-lg-start">
            © {{ thisTime }},
            Программа разработана в
            <a href="https://www.creative-tim.com" class="font-weight-bold" target="_blank">AppOffice Development</a>
            для внутреннего использования.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterModule",
  created() {
    this.loadThisTime();
  },
  data() {
    return {
      thisTime: '',
    };
  },
  props: {},
  methods: {
    loadThisTime(){
      this.thisTime = new Date().getFullYear();
    }
  },
};
</script>

<style lang="scss" scoped></style>