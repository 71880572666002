<template>
  <div v-if="pageLoad">
    <div v-if="!pageLoadErr" class="row mb-5 d-flex align-items-center">
      <div class="col-12 mb-3">
        <div class="col-auto">
          <h4>База данных 2GIS.KZ</h4>
          <div class="btn-group" role="group" aria-label="Basic example">
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle text-white" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                <span v-if="loadDataSpin" class="spinner-border spinner-border-sm me-3 text-white" aria-hidden="true"></span> Выбрать город
              </button>
              <ul class="dropdown-menu dropdown-menu-dark">
                <li v-for="ct in citys" :key="ct.name">
                  <p class="d-inline-flex gap-1">
                    <a class="dropdown-item" >
                      <div @click="ct.checked = !ct.checked; ct.citys = resetCheckedCitys(ct.citys, ct.checked); loadData();" class="form-check form-check-info text-start ps-0 checkboxed">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="ct.checked">
                      </div>
                      <button class="btn btn-link text-white btn-sm p-0 m-0" data-bs-toggle="collapse" :href="'#collapseCountry'+ct.id" role="button" aria-expanded="false" :aria-controls="'collapseCountry'+ct.id">{{ ct.name }}</button>
                    </a>
                  </p>
                  <div class="collapse" :id="'collapseCountry'+ct.id">
                    <a @click="pct.checked = !pct.checked; ct = updateCitysSelect(ct); loadData();" class="citySelected dropdown-item" v-for="pct in ct.citys" :key="pct.nameCity">
                      <div  class="form-check form-check-info text-start ps-0 checkboxed">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="pct.checked">
                      </div>
                      {{ pct.nameCity }}
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <button style="color: black;" class="btn btn-primary" type="button">
                Выбрано {{ formatNumber(this.countSelected) }}
            </button>
            <button @click="createXlsx()" :disabled="createXlsXLoad" v-if="this.countSelected > 0 && this.countSelected < 100000" class="btn btn-info" type="button">
                <span v-if="createXlsXLoad" class="spinner-border spinner-border-sm me-3 text-white" aria-hidden="true"></span> Скачать XLSX
            </button>
            <button @click="createSenders()" :disabled="createSendersLoad" v-if="this.countSelected > 0 && this.countSelected < 100000" class="btn btn-warning" type="button">
                <span v-if="createSendersLoad" class="spinner-border spinner-border-sm me-3 text-white" aria-hidden="true"></span> Создать рассылку
            </button>
          </div>
        </div>
      </div>
      <div class="row catList ">
        <div class="col-12">
          <div class="form-check form-check-info text-start ps-0 checkboxed">
            <input @change="allCheckedFunc();" class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="allChecked">
          </div>
          <strong :class="allChecked ? 'activeTextTitle' : 'opacityTextTitle'">
            Выбрать все
          </strong>
        </div>
      </div>
      <div v-for="category in cat" :key="category.id" class="row catList ">
        <div class="col-12">
          <div class="form-check form-check-info text-start ps-0 checkboxed">
            <input @change="category = this.updateChacked(category, category.checked); this.updatedCount();" class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="category.checked">
          </div>
          <img class="icon2gis" :src="category.image">  
          <strong :class="category.checked ? 'activeTextTitle' : 'opacityTextTitle'">
            {{ category.name }} 
          </strong>
          <button class="btn btn-sm btn-primary classIconCat" @click="category.show = !category.show"><span class="badge bg-danger mr-3 me-3">{{ formatNumber(category.countAds) }}</span> Открыть <i v-if="category.sub == 1" :class="category.show ? 'ion-chevron-down' : 'ion-chevron-right'"></i> </button>
        </div>
        <div v-if="category.sub == 1" v-show="category.show" class="col-12 mt-4">
          <div class="row pd1" v-for="pd1 in category.podcat" :key="pd1.id">
            <div class="col-12">
              <div class="form-check form-check-info text-start ps-0 checkboxed">
                <input @change="pd1 = this.updateChacked(pd1,pd1.checked); this.updatedCount();" class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="pd1.checked">
              </div>
              <strong :class="pd1.checked ? 'activeTextTitle' : 'opacityTextTitle'">{{ pd1.name }}</strong>
              <button v-if="pd1.sub == 1" class="btn btn-sm btn-primary classIconCat" @click="pd1.show = !pd1.show"><span class="badge bg-danger mr-3 me-3">{{ formatNumber(pd1.countAds) }}</span> Открыть <i :class="pd1.show ? 'ion-chevron-down' : 'ion-chevron-right'"></i> </button>
              <button v-else class="btn btn-sm btn-primary classIconCat"><span class="badge bg-danger mr-3 me-3">{{ formatNumber(pd1.countAds) }}</span></button>
            </div>
            <div v-if="pd1.sub == 1" v-show="pd1.show" class="col-12 mt-4">
              <div class="row pd2" v-for="pd2 in pd1.podcat" :key="pd2.id">
                <div class="col-12">
                  <div class="form-check form-check-info text-start ps-0 checkboxed">
                    <input @change="pd2 = this.updateChacked(pd2, pd2.checked); this.updatedCount();" class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="pd2.checked">
                  </div>
                  <strong :class="pd2.checked ? 'activeTextTitle' : 'opacityTextTitle'">{{ pd2.name }}</strong>
                  <button v-if="pd2.sub == 1" class="btn btn-sm btn-primary classIconCat" @click="pd2.show = !pd2.show"><span class="badge bg-danger mr-3 me-3">{{ formatNumber(pd2.countAds) }}</span> Открыть <i :class="pd2.show ? 'ion-chevron-down' : 'ion-chevron-right'"></i> </button>
                  <button v-else class="btn btn-sm btn-primary classIconCat"><span class="badge bg-danger mr-3 me-3">{{ formatNumber(pd2.countAds) }}</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrPageLoad :text="pageLoadErr" :myFunction="loadData" v-else></ErrPageLoad>
  </div>
  <PreloaderPage v-else></PreloaderPage>
</template>

<script>
export default {
  name: "DataBases",
  created() {
    this.loadData();
  },
  data() {
    return {
      cat: [],
      citys: [],
      countSelected: 0,
      delayTimer: null,
      loadDataSpin: false,
      allChecked: false,
      pageLoad: false,
      pageLoadErr: false,
      pageLoadErrText: '',
      createXlsXLoad: false,
      createSendersLoad: false,
    };
  },
  props: {},
  methods: {
    async createSenders(){
       this.createSendersLoad = true;
        try {
          const result = await this.$store.dispatch('postDataToServer', {
            methods: 'databases/create_senders',
            citys: this.citys.length > 0 ? this.citys : null,
            category: this.cat,
            myId: this.$store.state.user_info.id,
          });
          this.$router.push({ name: 'only_senders', params: { id: result.id } });
        } catch (error) {
          this.$notify({
            type: 'error',
            title: 'Произошла ошибка!',
            text: error.msg,
          });
        } finally {
          this.createSendersLoad = false;
        }
    },
    async createXlsx(){
      this.createXlsXLoad = true;
      try {
        const result = await this.$store.dispatch('postDataToServer', {
          methods: 'databases/create_xlsx',
          citys: this.citys.length > 0 ? this.citys : null,
          category: this.cat,
        });
        window.open(result.data, '_blank');
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка!',
          text: error.msg,
        });
      } finally {
        this.createXlsXLoad = false;
      }
    },
    allCheckedFunc(){
      var newcat = [];
      this.cat.forEach(element => {
        element = this.updateChacked(element, this.allChecked);
        element.checked = this.allChecked;
        newcat.push(element);
      });
      this.cat = newcat;
      this.updatedCount();
    },
    updatedCount(){
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
      }
     
      this.allChecked = false;
      this.delayTimer = setTimeout(async () => {
        try {
          this.loadDataSpin = true;
          const result = await this.$store.dispatch('postDataToServer', {
            methods: 'databases/updated_count_selected',
            citys: this.citys.length > 0 ? this.citys : null,
            category: this.cat,
          });

          this.countSelected = result.countSelected;
        } catch (error) {
          console.log(error);
          this.$notify({
            type: 'error',
            title: 'Произошла ошибка!',
            text: 'Не удалось обновить данные на сервере...',
          });
        } finally {
          this.delayTimer = null;
          this.loadDataSpin = false;
        }
      }, 1500);
    },
    updateChacked(ct, type) {
      var newCt = ct;

      if (newCt.sub == 1) {
        newCt.checked = type;
        var newPodCat = [];

        newCt.podcat.forEach(element => {
          if (element.sub == 1) {
            element = this.updateChacked(element, type);
          }
          element.checked = type;
          newPodCat.push(element);
        });

        newCt.podcat = newPodCat;
      } else {
        newCt.checked = type;
      }

      return newCt;
    },
    updateCitysSelect(ct){
      var newCt = ct;
      var uncheckPodcat = false;
      ct.citys.forEach(element => {
        if(!element.checked){
          uncheckPodcat = true;
        }
      });

      if(!uncheckPodcat){
        newCt['checked'] = true;
      }else{
        newCt['checked'] = false;
      }

      return newCt;
    },
    resetCheckedCitys(citys, checked){
      var newArray = [];
      citys.forEach(element => {
        element.checked = checked;
        newArray.push(element);
      });
      return newArray;
    },
    formatNumber(number) {
      if (number < 1000) {
        return number;
      } else if (number < 1000000) {
        return (number / 1000).toFixed(1) + 'K';
      } else {
        return (number / 1000000).toFixed(1) + 'M';
      }
    },
    async loadData() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
      }

      
      if (this.citys.length === 0) {
        this.pageLoad = false;
        this.pageLoadErr = false;
        this.pageLoadErrText = '';
      }

      this.delayTimer = setTimeout(async () => {
        try {
          this.loadDataSpin = true;
          const result = await this.$store.dispatch('postDataToServer', {
            methods: 'databases/get_category',
            citys: this.citys.length > 0 ? this.citys : null
          });

          this.cat = result.cat;
          if (this.citys.length === 0) {
            this.citys = result.citys;
          }
          this.countSelected = result.countSelected;
          this.allChecked = true;
          this.pageLoad = true;
          this.pageLoadErr = false;
          this.pageLoadErrText = '';
        } catch (error) {
          console.log(error);
          this.$notify({
            type: 'error',
            title: 'Произошла ошибка!',
            text: 'Не удалось обновить данные на сервере...',
          });

          this.pageLoad = true;
          this.pageLoadErr = true;
          this.pageLoadErrText = error.msg;
        } finally {
          this.delayTimer = null;
          this.loadDataSpin = false;
        }
      }, 1500);
    },
  },
};
</script>

<style lang="css" scoped>
  .activeTextTitle{
    font-weight: bold;
  }
  
  .opacityTextTitle{
    font-weight: 300;
    opacity: 0.7;
  }
  .citySelected{
    margin-left: 10px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .citySelected:first-child{
    margin-top: 10px;
  }
  .citySelected:last-child{
    margin-bottom: 30px;
  }
  .checkboxed{
    float: left;
    margin-right: 20px;
  }
  .pd1{
    padding-left: 70px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px #000000;
    cursor: pointer;
  }

  .pd1:last-child{
    border-bottom: none;
  }

  .pd2{
    padding-left: 70px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px #000000;
    cursor: pointer;
  }

  .pd2:last-child{
    border-bottom: none;
  }


  .icon2gis{
    width: 30px;
    margin-right: 20px;
  }

  .classIconCat{
    float: right;
  }

  .classIconCat2{
    float: right;
    margin-right: 20;
  }

  .classIconCat i{
    font-size: 13px;
    margin-left: 20px;
  }

  
  .catList{
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px #000000;
    cursor: pointer;
  }
  .catList:last-child{
    border-bottom: none;
  }
</style>