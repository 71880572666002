import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import OnlyChanelVue from '@/views/OnlyChanel.vue'
import OnlyChatVue from '@/views/OnlyChat.vue'
import DataBasesVue from '@/views/DataBases.vue'
import OnlySendersVue from '@/views/OnlySenders.vue'
import ListSendersVue from '@/views/ListSenders.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/only_chanel/:channelId',
    name: 'only_chanel',
    component: OnlyChanelVue,
    props: true,
  },
  {
    path: '/only_chanel/:channelId/:chatId',
    name: 'only_chat',
    component: OnlyChatVue,
    props: true,
  },
  {
    path: '/data_bases',
    name: 'data_bases',
    component: DataBasesVue,
  },
  {
    path: '/only_senders/:id',
    name: 'only_senders',
    component: OnlySendersVue,
    props: true,
  },
  {
    path: '/list_senders',
    name: 'list_senders',
    component: ListSendersVue,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
