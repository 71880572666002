<template>
  <div class="container">
    <div class="row">
      <div
        class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
        <div
          class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
          style="background-image: url('assets/img/illustrations/illustration-signup.jpg'); background-size: cover;">
        </div>
      </div>
      <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column vh-100 ms-auto me-auto ms-lg-auto me-lg-5 align-items-center align-items-center justify-content-center">
        <div class="card card-plain">
          <div class="card-header">
            <img src="assets/img/logo-ct-dark.png" class="navbar-brand-img w-100" alt="main_logo">
          </div>
          <div class="card-body">
            <form role="form">
              <div class="w-100 errAuth" v-if="showDopErr">
                {{ textDopErr }}
              </div>
              <div class="input-group input-group-outline mb-3"> 
                <input v-model="email" placeholder="E-mail" id="userEmail" type="email" class="form-control">
                <div class="w-100 errAuth" v-if="emailErr">
                    Не коректный E-mail...
                </div>
              </div>

              <div class="input-group input-group-outline mb-3">
                <input v-model="password" placeholder="Пароль" type="password" class="form-control">
                <div class="w-100 errAuth" v-if="passwordErr">
                    Не коректный пароль...
                </div>
              </div>

              <div class="text-center">
                <button @click="auth()" :disabled="isAuth" type="button" class="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0">
                  <span v-if="!isAuth">Войти</span>
                  <span v-if="isAuth" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthPage",
  created() { },
  data() {
    return {
      email: '',
      password: '',
      isAuth: false,
      emailErr: false,
      passwordErr: false,
      showDopErr: false,
      textDopErr: '',
    };
  },
  props: {},
  methods: {
    async auth(){
      if(this.email.length > 2 && this.email.includes('@') && this.email.includes('.')){
        this.emailErr = false;
      }else{
        this.emailErr = true;
      }

      if(this.password.length < 5){
        this.passwordErr = true;
      }else{
        this.passwordErr = false;
      }
      if(this.emailErr || this.passwordErr){
        return false;
      }

      this.isAuth = true;

     await this.$store.dispatch('postDataToServer', {
        methods: 'auth/auth',
        email: this.email,
        password: this.password,
      }).then(data=>{
        this.$store.state.server_info = data['server_info'];
        this.$store.state.user_info = data['user_info'];
        this.$store.state.auth = true;
        window.localStorage.setItem('login', this.email);
        window.localStorage.setItem('password', this.password);
      }).catch(err=>{
        if(err['errorType'] == null){
          if(err['errorType'] == 'email'){
            this.showDopErr = false;
            this.textDopErr = '';
            this.emailErr = true;
            this.passwordErr = false;
          }else if(err['errorType'] == 'password'){
            this.showDopErr = false;
            this.textDopErr = '';
            this.emailErr = false;
            this.passwordErr = true;
          }else{
            this.showDopErr = true;
            this.textDopErr = err['msg'];
          }
        }else{
          this.showDopErr = true;
          this.textDopErr = err['msg'];
        }
      });

      this.isAuth = false;
    }
  },
};
</script>

<style lang="css" scoped>
  .errAuth{
    color: red;
    font-style: italic;
    font-size: 12px;
    padding-top: 5px;
  }
</style>