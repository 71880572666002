import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PreloaderPage from './components/template/PreloaderPage.vue'
import ErrorLoadPageVue from './components/template/ErrorLoadPage.vue'
import AuthPageVue from './views/AuthPage.vue'
import { InstallCodemirro } from "codemirror-editor-vue3"
import Notifications from '@kyvg/vue3-notification'

createApp(App)
.use(store)
.use(Notifications)
.component('PreloaderPage', PreloaderPage)
.component('ErrPageLoad', ErrorLoadPageVue)
.component('AuthPage', AuthPageVue)
.use(InstallCodemirro)
.use(router)
.mount('#app')
