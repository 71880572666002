<template>
  <div class="preloaderPage d-flex align-items-center justify-content-center ">
      <div class="title">
        <i class="ion-code-download"></i>
        <h3>Произошла ошибка</h3>
        <span>{{ text }}</span>
        <br><br>
        <button @click="myFunction" class="btn btn-primary">Повторить</button>
      </div>
  </div>
</template>

<script>
export default {
  name: "ErrorLoad",
  created() {},
  data() {
    return {};
  },
  props: {
    myFunction: {
      type: Function,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  methods: {},
};
</script>

<style lang="css" scoped>
  .preloaderPage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }
  .title{
    text-align: center;
  }
  .title i{
    font-size: 128px;
  }
  .spinner-border{
    margin: auto;
  }
</style>