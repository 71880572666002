<template>
  <aside class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3   bg-gradient-dark" id="sidenav-main">
    <div class="sidenav-header">
      <i class="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
      <a class="navbar-brand m-0" style="cursor: pointer;" @click="openPage('home')" target="_blank">
        <img src="assets/img/logo-ct.png" class="navbar-brand-img h-100" alt="main_logo"/>
      </a>
    </div>
    <hr class="horizontal light mt-0 mb-2">
    <div class="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link text-white " :class="this.$route.name == 'home' || this.$route.name == 'only_chanel' ? 'active bg-gradient-primary' : ''" style="cursor: pointer;" @click="openPage('home')">
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">dashboard</i>
            </div>
            <span class="nav-link-text ms-1">Whatsapp Каналы</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white " :class="this.$route.name == 'data_bases' ? 'active bg-gradient-primary' : ''" style="cursor: pointer;" @click="openPage('data_bases')">
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">table_view</i>
            </div>
            <span class="nav-link-text ms-1">База контактов</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white " :class="this.$route.name == 'list_senders' || this.$route.name == 'only_senders' ? 'active bg-gradient-primary' : ''" style="cursor: pointer;" @click="openPage('list_senders');">
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">table_view</i>
            </div>
            <span class="nav-link-text ms-1">Рассылки</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="sidenav-footer position-absolute w-100 bottom-0 ">
      <div class="mx-3">
        <button @click="logOute()" class="btn btn-outline-primary mt-4 w-100" type="button">
          Выйти из системы
        </button>
        <a @click="serverOnOff()" class="btn  w-100" :class="this.$store.state.server_info.on_off == 1 ? 'bg-gradient-danger' : 'bg-gradient-primary'" type="button">
          {{ this.$store.state.server_info.on_off == 1 ? 'Отключить сервер' : 'Включить сервер' }}
        </a>
      </div>
    </div>
  </aside>
</template>

<script> 
export default {
  name: "MenuModule",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {
    async logOute(){
      window.localStorage.removeItem('login');
      window.localStorage.removeItem('password');
      this.$store.state.server_info = {};
      this.$store.state.user_info = {};
      this.$store.state.auth = false;
            
    },
    async serverOnOff(){
      await this.$store.dispatch('postDataToServer', {
        methods: 'server/server_state',
        myId: this.$store.state.user_info.id,
      })
      .then(result => {
        this.$store.state.server_info['on_off'] = result.server_state;
      })
      .catch(error => {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка!',
          text: error.msg,
        });
      });
    },
    openPage(page){
      this.$router.push({ name: page });
    }
  },
};
</script>

<style lang="scss" scoped></style>