<template>
  <div>
    <div class="row mb-5 d-flex align-items-center">
      <div class="col-12 mb-3">
        <div class="col-auto">
          <h4>Список рассылок </h4>
          <span>Действующие и запланированные рассылки.</span>
        </div>
      </div>
      <hr>
      <div class="col-12 mb-3">
        <table v-if="listSenders.length > 0" class="table table-dark text-center">
          <thead class="bg-dark">
            <th>ID</th>
            <th>Название</th>
            <th>Дата создания</th>
            <th>Кол-во контактов</th>
            <th>Состояние</th>
            <th>Отправлено</th>
            <th>Ожидает</th>
            <th>Ошибок</th>
            <th></th>
          </thead>
          <tbody>
            <tr v-for="send in listSenders" :key="send.id">
              <td>№{{ send.id }}</td>
              <td>{{ send.title }}</td>
              <td>{{ send.date_create }}</td>
              <td>{{ send.count_contacts }}</td>
              <td>
                <span class="badge" :class="send.state == 0 ? 'bg-danger' : 'bg-primary'">{{ send.state == 0 ? 'Отключена' : 'Включена' }}</span>
              </td>
              <td><span class="badge bg-primary">{{ send.count_send }}</span></td>
              <td><span class="badge bg-warning">{{ send.count_waiting }}</span></td>
              <td><span class="badge bg-danger">{{ send.count_errors }}</span></td>
              <td>
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button @click="this.delete(send.id);" class="btn btn-sm mb-0 btn-outline-danger">Удалить</button>
                  <button @click="this.onOffSenders(send.id);" class="btn btn-sm mb-0 " :class="send.state == 0 ? 'btn-outline-primary' : 'btn-outline-danger'">{{ send.state ==0 ? 'Включить' : 'Отключить' }}</button>
                  <button @click="this.$router.push({ name: 'only_senders', params: { id: send.id } });" class="btn btn-outline-light btn-sm mb-0">Редактировать</button>
                </div> 
              </td>
            </tr>
          </tbody>
        </table>
        <div class="emptyBlock" v-else>
          <i class="ion-ios-box-outline"></i>
          <h3>Нет данных!</h3>
          <p>В данный момент нет ни одной рассылки...</p>
          <button @click="this.$router.push({ name: 'data_bases' });" class="btn btn-primary">Создать рассылку</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListSenders",
  created() {
    this.getListLatter();
  },
  data() {
    return {
      loadPage: false,
      loadPageErr: false,
      loadPageErrText: '',
      listSenders: [],
    };
  },
  props: {},
  methods: {
    async delete(id){
      try {
        const result = await this.$store.dispatch('postDataToServer', {
          methods: 'senders/delete_senders',
          myId: this.$store.state.user_info.id,
          sender: id,
        });
        
        this.listSenders = result.list;
        this.loadPageErr = false;
        this.loadPageErrText = '';
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка!',
          text: error.msg,
        });
        this.loadPageErr = true;
        this.loadPageErrText = error.msg;
      } finally {
        this.loadPage = true;
      }
    },
    async onOffSenders(id){
      try {
        const result = await this.$store.dispatch('postDataToServer', {
          methods: 'senders/on_off_senders',
          myId: this.$store.state.user_info.id,
          sender: id,
        });
        
        this.listSenders = result.list;
        this.loadPageErr = false;
        this.loadPageErrText = '';
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка!',
          text: error.msg,
        });
        this.loadPageErr = true;
        this.loadPageErrText = error.msg;
      } finally {
        this.loadPage = true;
      }
    },
    async getListLatter(){
      try {
        const result = await this.$store.dispatch('postDataToServer', {
          methods: 'senders/list_senders',
          myId: this.$store.state.user_info.id,
        });
        
        this.listSenders = result.list;
        this.loadPageErr = false;
        this.loadPageErrText = '';
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка!',
          text: error.msg,
        });
        this.loadPageErr = true;
        this.loadPageErrText = error.msg;
      } finally {
        this.loadPage = true;
      }
    }
  },
};
</script>

<style lang="css" scoped>
  .emptyBlock{
    text-align: center;
  }
  .emptyBlock i{
    font-size: 98px;
  }
</style>