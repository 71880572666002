<template>
  <div v-if="loadData">
    <div v-if="!loadDataErr" class="row mb-5 d-flex align-items-center">
      <div class="col-12 mb-3">
        <div class="col-auto">
          <h4>Название рассылки</h4>
          <div class="row mt-3 mb-3">
            <div class="col-md-3">
              <div class="stat-cards-1">
                <div class="row d-flex align-items-center justify-content-center">
                  <div class="col-auto text-center">
                    <i class="ion-ios-paperplane-outline iconStats"></i>
                  </div>
                  <div class="col-auto text-left">
                    <strong>Ожидают</strong><br>
                    Отправки
                  </div>
                  <div class="col-auto text-center counterStat">
                    {{ sendersInfo.count_waiting }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="stat-cards-2">
                <div class="row d-flex align-items-center justify-content-center">
                  <div class="col-auto text-center">
                    <i class="ion-chatbubble-working iconStats"></i>
                  </div>
                  <div class="col-auto text-left">
                    <strong>Отправлено</strong><br>
                    Сообщений
                  </div>
                  <div class="col-auto text-center counterStat">
                    {{ sendersInfo.count_send }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="stat-cards-3">
                <div class="row d-flex align-items-center justify-content-center">
                  <div class="col-auto text-center">
                    <i class="ion-chatbubble-working iconStats"></i>
                  </div>
                  <div class="col-auto text-left">
                    <strong>Всего</strong><br>
                    Инстансов
                  </div>
                  <div class="col-auto text-center counterStat">
                     {{ sendersInfo.chanels.length }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="stat-cards-4">
                <div class="row d-flex align-items-center justify-content-center">
                  <div class="col-auto text-center">
                    <i class="ion-pie-graph iconStats"></i>
                  </div>
                  <div class="col-auto text-left">
                    <strong>Ошибок при</strong><br>
                    Отправке
                  </div>
                  <div class="col-auto text-center counterStat">
                    {{ sendersInfo.count_errors }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button @click="tabs = 1" class="btn" :class="tabs == 1 ? 'btn-primary' :'btn-info'" type="button">
                Настройки
            </button>
            <button @click="tabs = 2" class="btn" :class="tabs == 2 ? 'btn-primary' :'btn-info'" type="button">
                Инстансы
            </button>
            <button @click="tabs = 3" class="btn" :class="tabs == 3 ? 'btn-primary' :'btn-info'" type="button">
                Стартовые сообщения
            </button>
            <button @click="getReport()" :disabled="getReportState" class="btn btn-secondary" type="button">
                <span v-if="!getReportState">Скачать отчет</span>
                <span v-if="getReportState" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div v-if="tabs == 1">
          <div class="text-center w-100">
            <form class="w-100">
              <div class="mb-3 input-group input-group-outline w-100">
                <input v-model="sendersInfo.title" placeholder="Название рассылки" name="chanelName" type="text" class="form-control w-100" id="titleChanel">
              </div>
              <div class="mb-3 input-group input-group-outline w-100">
                <select v-model="sendersInfo.type_generate_start_message" class="form-select form-control h-100">
                  <option value="0">Сообщения генерирует ЕВА</option>
                  <option value="1">Сообщения из системы</option>
                </select>
              </div>
              <div class="mb-3 input-group input-group-outline w-100">
                <select v-model="sendersInfo.weekend_send" class="form-select form-control h-100">
                  <option value="0">Выходные отправлять</option>
                  <option value="1">Выходные не отправлять</option>
                </select>
              </div>
              <div class="mb-3 input-group input-group-outline w-100">
                <input v-model="sendersInfo.time_start" placeholder="Время начала рассылки" type="time" class="form-control w-100" id="titleChanel">
              </div>
              <div class="mb-3 input-group input-group-outline w-100">
                <input v-model="sendersInfo.time_off" placeholder="Время завершения рассылки" type="time" class="form-control w-100" id="titleChanel">
              </div>
              <button @click="update()" :disabled="updatedData" class="btn btn-primary w-100">
                <span v-if="!updatedData">Сохранить</span>
                <span v-if="updatedData" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
              </button>
            </form>
          </div>
        </div>
        <div v-if="tabs == 2">
          <form v-if="allChanels.length > 0" class="w-100">
            <div class="input-group mb-3 input-group-outline w-100 selectGroup">
              <select v-model="addInstanceSelectID" class="form-select form-control h-100">
                <option value="0" selected disabled>Выбрать канал</option>
                <option v-for="ch in allChanels" :key="ch.id" :value="ch.id">{{ ch.name }}</option>
              </select>
              <button @click="addInstance()" :disabled="loadAddInstance" class="btn btn-outline-secondary" type="button" id="button-addon1">
                <span v-if="!loadAddInstance">Добавить</span>
                <span v-if="loadAddInstance" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
              </button>
            </div>
          </form>
          <table class="table table-dark text-center">
            <thead class="bg-dark">
              <th>Аватар</th>
              <th>ID</th>
              <th>Название</th>
              <th>Статус</th>
              <th></th>
            </thead>
            <tbody>
              <tr v-for="chanel in sendersInfo.chanels" :key="chanel.id">
                <td class="align-middle">
                  <img :src="chanel['avatar'].length > 0 ? chanel['avatar'] : this.$store.state.server_info.defoult_avatar" class="avatar avatar-md me-3" alt="xd"/>
                </td>
                <td class="align-middle">{{ chanel.id }}</td>
                <td class="align-middle">{{ chanel.name }}</td>
                <td class="align-middle">{{ chanel.status }}</td>
                <td class="align-middle">
                  <button @click="chanel.load = true; chanel = deleteChanel(chanel);" class="btn btn-outline-danger btn-sm mt-2">
                    <span v-if="chanel.load" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span v-if="!chanel.load">Удалить</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="tabs == 3">
          <div v-for="smsg in sendersInfo.start_messages" :key="smsg.id" class="mb-3 input-group input-group-outline w-100">
              <textarea v-model="smsg.message" placeholder="Стартовое сообщение" rows="7" class="form-control w-100"></textarea>
          </div>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button @click="saveStrMsg()" :disabled="savedStartedMessages" class="btn btn-primary" type="button">
                <span v-if="savedStartedMessages" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span v-if="!savedStartedMessages">Сохранить</span>
            </button>
            <button @click="addStartedMessagesFunc()" :disabled="addStartMessages" class="btn btn-warning" type="button">
                <span v-if="addStartMessages" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span v-if="!addStartMessages">Добавить</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <ErrPageLoad :text="loadDataErrText" :myFunction="loadDataFunc" v-else></ErrPageLoad>
  </div>
  <PreloaderPage v-else></PreloaderPage>
</template>

<script>
export default {
  name: "OnlySenders",
  created() {
    this.loadDataFunc();
  },
  data() {
    return {
       tabs: 1,
       sendersInfo: {},
       loadData: false,
       loadDataErr: false,
       loadDataErrText: '',
       updatedData: false,
       allChanels: [],
       loadAddInstance: false,
       addInstanceSelectID: 0,
       addStartMessages: false,
       savedStartedMessages: false,
       getReportState: false,
    };
  },
  props: {},
  methods: {
    async getReport(){
      this.getReportState = true;
      try {
        const result = await this.$store.dispatch('postDataToServer', {
          methods: 'senders/get_report',
          myId: this.$store.state.user_info.id,
          id: this.$route.params.id,
        });
        window.open(result.data, '_blank');
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка!',
          text: error.msg,
        });
      } finally {
        this.getReportState = false;
      }
    },
    async saveStrMsg(){
      this.savedStartedMessages = true;
      try {
        const result = await this.$store.dispatch('postDataToServer', {
          methods: 'senders/save_str_msg',
          myId: this.$store.state.user_info.id,
          id: this.$route.params.id,
          msgs: this.sendersInfo.start_messages,
        });
        this.sendersInfo = result.info;
        this.allChanels = result.allChanels;
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка!',
          text: error.msg,
        });
      } finally {
        this.savedStartedMessages = false;
      }
    },
    async addStartedMessagesFunc(){
      this.addStartMessages = true;
      try {
        const result = await this.$store.dispatch('postDataToServer', {
          methods: 'senders/add_start_mess',
          myId: this.$store.state.user_info.id,
          id: this.$route.params.id,
        });
        this.sendersInfo = result.info;
        this.allChanels = result.allChanels;
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка!',
          text: error.msg,
        });
      } finally {
        this.addStartMessages = false;
      }
    },
    async deleteChanel(chanel){
      try {
        const result = await this.$store.dispatch('postDataToServer', {
          methods: 'senders/delete_instance',
          myId: this.$store.state.user_info.id,
          id: this.$route.params.id,
          delete_id: chanel.id,
        });
        this.sendersInfo = result.info;
        this.allChanels = result.allChanels;
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка!',
          text: error.msg,
        });
      } finally {
        chanel.load = false;
      }
      return chanel;
    },
    async addInstance(){
      this.loadAddInstance = true;
      try {
        const result = await this.$store.dispatch('postDataToServer', {
          methods: 'senders/add_instance',
          myId: this.$store.state.user_info.id,
          id: this.$route.params.id,
          add_id: this.addInstanceSelectID,
        });
        this.sendersInfo = result.info;
        this.allChanels = result.allChanels;
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка!',
          text: error.msg,
        });
      } finally {
        this.loadAddInstance = false;
      }
    },
    async update(){
      this.updatedData = true;
      try {
        const result = await this.$store.dispatch('postDataToServer', {
          methods: 'senders/update_senders_info',
          myId: this.$store.state.user_info.id,
          id: this.$route.params.id,
          info: this.sendersInfo,
        });
        this.sendersInfo = result.info;
        this.allChanels = result.allChanels;
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка!',
          text: error.msg,
        });
      } finally {
        this.updatedData = false;
      }
    },
    async loadDataFunc(){
      this.loadData = false;
      try {
        const result = await this.$store.dispatch('postDataToServer', {
          methods: 'senders/get_senders_info',
          myId: this.$store.state.user_info.id,
          id: this.$route.params.id,
        });
        this.loadDataErr = false;
        this.loadDataErrText = '';
        this.sendersInfo = result.info;
        this.allChanels = result.allChanels;
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Произошла ошибка!',
          text: error.msg,
        });
        this.loadDataErr = true;
        this.loadDataErrText = error.msg;
      } finally {
        this.loadData = true;
      }
    }
  },
};
</script>

<style lang="css" scoped>
  .selectGroup{
    height: 50px;
  }

  .selectGroup button{
    height: 100%;
  }
  .selectGroup select{
    height: 100%;
  }
</style>