<template>
  <div v-if="loadApp">
    <div v-if="!errLoadApp">
      <div v-if="$store.state.auth">
        <MenuModule/>
        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
          <div class="container-fluid py-4">
            <router-view/>
            <FooterModule/>
          </div>
        </main>
      </div>
      <div v-else>
        <AuthPage/>
      </div>
    </div>
    <ErrPageLoad :text="textErrLoad" :myFunction="loadAppFunc" v-else></ErrPageLoad>
  </div>
  <PreloaderPage v-else></PreloaderPage>
  <notifications classes="my-notification" position="bottom right" />
</template>

<style>
  .my-notification {
    margin: 0 5px 5px;
    padding: 10px;
    font-size: 12px;
    color: #ffffff;

    background: #44a4fc;
    border-left: 5px solid #187fe7;

    &.success {
      background: #68cd86;
      border-left-color: #42a85f;
    }

    &.warn {
      background: #ffb648;
      border-left-color: #f48a06;
    }

    &.error {
      background: #e54d42;
      border-left-color: #b82e24;
    }
  }
</style>

<script>
  import MenuModule from './components/template/MenuModule.vue';
  import FooterModule from './components/template/FootterModule.vue';
  export default {
    name: "App",
    created() {
      this.loadAppFunc();
      setInterval(() => {
        this.loadAppFunc();
      }, 2000);
    },
    data() {
      return {
        loadApp: false,
        errLoadApp: false,
        textErrLoad: '',
      };
    },
    props: {},
    components: {
      MenuModule,
      FooterModule
    },
    methods: {
      async loadAppFunc(){
        var login = window.localStorage.getItem('login');
        var password = window.localStorage.getItem('password');

        if(login == null || password == null){
          this.loadApp = true;
          this.errLoadApp = false;
          this.textErrLoad = '';
          this.$store.commit('setAuth', false);
        }else{
          await this.$store.dispatch('postDataToServer', {
            methods: 'auth/auth',
            email: login,
            password: password,
          }).then(data=>{
            this.$store.state.server_info = data['server_info'];
            this.$store.state.user_info = data['user_info'];
            this.$store.state.auth = true;
          });
          this.loadApp = true;
          this.errLoadApp = false;
          this.textErrLoad = '';
        }
      }
    },
  };
</script>
